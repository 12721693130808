// ** React Imports
import {ReactNode} from "react"

// ** Configs
import themeConfig from "@wildeye/frontend/src/configs/themeConfig"

// ** MUI Imports
import {Theme} from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import Box from "@mui/material/Box"

// ** Layout Imports
// !Do not remove this Layout import
import Layout from "@wildeye/frontend/src/@core/layouts/Layout"

// ** Component Import
import ServerSideVerticalNavItems from "./components/vertical/ServerSideNavItems"
import ServerSideHorizontalNavItems from "./components/horizontal/ServerSideNavItems"

import VerticalAppBarContent from "./components/vertical/AppBarContent"
import HorizontalAppBarContent from "./components/horizontal/AppBarContent"

// ** Hook Import
import {useSettings} from "@wildeye/frontend/src/@core/hooks/useSettings"
import Link from "next/link"
import WarmlinkShareButton from "../warmlink/components/WarmlinkShareButton"
import {useSession} from "next-auth/react"
import {UserIncludes} from "@wildeye/shared"

interface Props {
  children: ReactNode
  contentHeightFixed?: boolean
}

const AppBrand = () => {
  const {data: session} = useSession()
  const user = session?.user as unknown as UserIncludes
  return (
    <Box sx={{display: "flex", alignItems: "center"}}>
      <Link href='/store'>
        <img src={themeConfig.logo} alt='logo' width={themeConfig.logoWidth}/>
      </Link>
      {user?.warmlink_aff_link &&
        <WarmlinkShareButton
          user={user}
          offerTitle={'Warmlink'}
          offerId={'warmlink'}
          linkType='link'
          sx={{ml: -0.25, mb: 1.25}}/>}
    </Box>
  )
}

const UserLayout = ({children, contentHeightFixed}: Props) => {
  // ** Hooks
  const {settings, saveSettings} = useSettings()

  // ** Vars for server side navigation
  const {menuItems: verticalMenuItems} = ServerSideVerticalNavItems()
  const {menuItems: horizontalMenuItems} = ServerSideHorizontalNavItems()

  /**
   *  The below variable will hide the current layout menu at given screen size.
   *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
   *  You can change the screen size from which you want to hide the current layout menu.
   *  Please refer useMediaQuery() hook: https://mui.com/material-ui/react-use-media-query/,
   *  to know more about what values can be passed to this hook.
   *  ! Do not change this value unless you know what you are doing. It can break the template.
   */
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"))

  if (hidden && settings.layout === "horizontal") {
    settings.layout = "vertical"
  }

  return (
    <Layout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      contentHeightFixed={contentHeightFixed}
      verticalLayoutProps={{
        navMenu: {
          navItems: verticalMenuItems,
          branding: () => <AppBrand/>
        },
        appBar: {
          content: props => (
            <VerticalAppBarContent
              hidden={hidden}
              settings={settings}
              saveSettings={saveSettings}
              toggleNavVisibility={props.toggleNavVisibility}
            />
          )
        }
      }}
      {...(settings.layout === "horizontal" && {
        horizontalLayoutProps: {
          navMenu: {
            navItems: horizontalMenuItems
          },
          appBar: {
            branding: () => <AppBrand/>,
            content: () => <HorizontalAppBarContent hidden={hidden} settings={settings} saveSettings={saveSettings}/>
          }
        }
      })}
    >
      {children}
    </Layout>
  )
}

export default UserLayout
