export const GLOBAL_COOLDOWN_DAYS = 14
export const MIN_MESSAGES_DAILY = 25
export const MAX_MESSAGES_DAILY = 150
export const MIN_MESSAGE_FREQUENCY = 4
export const MAX_MESSAGE_FREQUENCY = 12
export const PERSONAL_NOTE_MAX_CHARS = 140
export const PERSONAL_NOTE_MAX_ROWS = 6
export const EXTENSION_COOKIE_NAME = 'wk'
export const EXTENSION_COOKIE_MAX_AGE_DAYS = 365
export const EXTENSION_COOKIE_MAX_AGE_SEC = EXTENSION_COOKIE_MAX_AGE_DAYS * 24 * 60 * 60
export const EXTENSION_COOKIE_MAX_AGE_MS = EXTENSION_COOKIE_MAX_AGE_SEC * 1000
export const WARMLINK_COOKIE_NAME = 'warmlink'
export const WARMER_ID_COOKIE_MAX_AGE_SEC = 365 * 24 * 60 * 60 // seconds
export const WRAPLINK_COOKIE_NAME = 'wraplink'
export const WRAPPER_ID_COOKIE_MAX_AGE_SEC = 365 * 24 * 60 * 60 // seconds
export const PROCURING_CAUSE = true // overwrite older cookies for referral credit
export const GREGORIAN_DAYS_PER_YEAR = 365.2425
export const MAX_REVENUE_SHARE_AMOUNT = 5_000_000
export const MAX_CONNETIONS = 30_000
export const OFFERTARGETS_PAGE_SIZE = 3000
export const WRAPLINKS_MAX_ALLOWED = 100
export const INTERSTITIAL_DELAY = 3500
export const AUTHORITY_LEVELS = 3
export const AUTHORITY_SUBLEVELS = 3
export const published_unsub_warmlink_url = `https://unsub.warmlink.io`

// Downline Compensation
export const TSplits = {
  buyer: 25,
  t1: {std: 50, company: 45, buyer: {member: 35, company: 35}},
  t2: {std: 2.5, t4: 2.75},
  t3: {std: 2.5, t4: 2},
  t4: {std: 0.25, active: false},
}

// Dashboard Cards
export const DASH_ENASBLE_WELCOME = true
export const DASH_ENASBLE_READYTOPROMOTE = false
export const DASH_ENASBLE_YOURTEAM = true


// ** Proxycurl
//
// Credits
// https://nubela.co/proxycurl/docs#proxycurl-overview-credits
// Credits are charged for 200 and 404 responses.
// 429 is a rate limit signal that is not charged for.
//
// Rate limits
// https://nubela.co/proxycurl/docs#proxycurl-overview-rate-limit
// Proxycurl permits 300 requests/minute.
//
// Vercel Runtime max duration limit
// https://vercel.com/docs/functions/runtimes#size-limits
// Vercel limits us to 5 minutes (300s) max duration for Pro.
// We gracefully stop fetching Proxycurl profiles after 4:50 to be safe.
// The cron job will restart shortly to continue fetching more if necessary.
export const PROXYCURL_CRON = false
export const PROXYCURL_CRON_MINS = 1
export const PROXYCURL_CRON_SEC = 290
export const PROXYCURL_TEST = true
export const PROXYCURL_FETCH_MS = 60 / 300 * 1000
