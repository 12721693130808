import {Role} from "@wildeye/prisma/user-roles"

/**
 *  Set Home URL based on User Roles
 */
const getHomeRoute = (role: string) => {
  switch (role) {
    case Role.admin:
      return "/store"
    case Role.member:
      return "/store"
  }
  return "/store"
}

export default getHomeRoute
